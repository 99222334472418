/* global erp_noscal, ajaxcom, erp_design, erp, ew, CURRENT_LANG */

import {Calendar} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';

erp_noscal.init_noscal = function(config = {}){
	const calendarEl = document.getElementById('noscal');
	
	erp_noscal.config = {
		plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
		
		customButtons: {
			addButton: {
				text: ew('Add'),
				click: erp_noscal.show_event_form,
			},
		},
		
		header: {
			left: 'title',
			center: 'timeGridDay,timeGridWeek,dayGridMonth,listMonth,listWeek,listDay',
			right: 'today,prev,next',
		},
		footer: {
			center: '',
		},
		
		buttonText: {
			listMonth: ew('listMonth'),
			listWeek: ew('listWeek'),
			listDay: ew('listDay'),
		},
		
		locales: allLocales,
		locale: CURRENT_LANG,
		
		editable: true,
		displayEventEnd: true,
		firstDay: 1,
		
		defaultView: window.screen.width > 800? 'dayGridMonth' : 'listMonth',
		
		/**
		 * Displays the pre-filled edit window
		 *
		 * @param event
		 */
		eventClick: function({event}){
			const tmpAjaxObject = {
				event_id: event.id,
			};
			
			erp_noscal.show_event_form(tmpAjaxObject);
		},
		
		/**
		 * Display an empty edit window with pre-filled date and/or time
		 *
		 * @param date
		 */
		dateClick: function({date}){
			const currentTimeOfDay = new Date();
			date.setHours(currentTimeOfDay.getHours(), currentTimeOfDay.getMinutes());
			
			const tmpAjaxObject = {
				event_id: null,
				date: erp_noscal.get_event_timestamp(date),
			};
			
			erp_noscal.show_event_form(tmpAjaxObject);
		},
		
		/**
		 * Updates event date/time on drop
		 *
		 * @param {Object} event
		 * @param {function} revert
		 */
		eventDrop: function({event, revert}){
			erp.msg(
				`"${event.title}" wirklich nach "${event.start.toLocaleString()}" verschieben?`,
				'confirm',
				() => erp_noscal.save_event(erp_noscal.transform_event_object_for_ajax_call(event)),
				() => revert()
			);
		},
		
		/**
		 * Shows event info in an extra window
		 *
		 * @param {Object} event
		 * @param {HTMLElement} el
		 */
		eventMouseEnter: function({event, el}){
			const infoWindow = el.nextElementSibling;
			if(infoWindow){
				infoWindow.classList.toggle('active');
			}
		},
		eventMouseLeave: function({event, el}){
			const infoWindow = el.nextElementSibling;
			if(infoWindow){
				infoWindow.classList.toggle('active');
			}
		},
		
		/**
		 * Adds the container for the on-hover info window
		 *
		 * @param {Object} event
		 * @param {HTMLElement} el
		 */
		eventPositioned: function({event, el}){
			if(event.extendedProps.notes){
				const container = el.parentElement;
				const infoWindow = document.createElement('div');
				infoWindow.classList.add('erp_noscal_info-window');
				infoWindow.innerHTML = event.extendedProps.notes;
				container.append(infoWindow);
			}
		},
		
		/**
		 * Adds an "add" button to a day cell in the month view
		 *
		 * @param date
		 * @param {HTMLElement} el
		 * @param {Object} view
		 */
		dayRender: function({date, el, view}){
			// do this for the month view ONLY
			if(view.type === 'dayGridMonth'){
				const addButton = document.createElement('a');
				addButton.classList.add('noscal_floating_add_button');
				addButton.innerHTML = '<i class="fa fa-plus"></i>';
				addButton.setAttribute(
					'href',
					`javascript:erp_noscal.show_event_form({date: ${erp_noscal.get_event_timestamp(date)});`
				);
				addButton.setAttribute('target', '_self');
				
				el.append(addButton);
			}
		},
		
		datesRender({view}){
			const startDate = view.activeStart;
			// const endDate = view.activeEnd;
			const tmpAjaxObject = {
				year: startDate.getFullYear(),
			};
			
			ajaxcom({
				m: 'erp_noscal',
				s: 'interface',
				a: 'check_holidays',
				ci: 1,
				q: tmpAjaxObject,
				hf: {
					success: (result) => {
						// reload events to reflect changes
						if(result.do_refresh){
							erp_noscal.fullcalendar.refetchEvents();
						}
					},
					error: 'msg',
				},
			});
		},
		
		eventSources: [
			{
				events: function(info, successCallback, failureCallback){
					const tmpAjaxObject = erp.load_all_fields_for_ajax('erp_noscal_manager_filter', 0, 1);
					tmpAjaxObject.start = erp_noscal.get_event_timestamp(info.start);
					tmpAjaxObject.end = erp_noscal.get_event_timestamp(info.end);
					ajaxcom({
						m: 'erp_noscal',
						s: 'interface',
						a: 'js_events',
						// 'ci': 1,
						q: tmpAjaxObject,
						hf: {
							success: (result) => successCallback(result.data),
							error: (msg) => failureCallback(msg),
						},
					});
				},
			},
			
			// special days
			/*
			{
			  events: function (info, successCallback, failureCallback) {
				const tmpAjaxObject = {
				  start: erp_noscal.get_event_timestamp(info.start),
				  end: erp_noscal.get_event_timestamp(info.end),
				  special_days: true,
				};
	  
				ajaxcom({
				  m: 'erp_noscal',
				  s: 'interface',
				  a: 'get_events',
				  // 'ci': 1,
				  q: tmpAjaxObject,
				  hf: {
					success: (result) => successCallback(result.data),
					error: (msg) => failureCallback(msg),
				  },
				});
			  },
			},
			*/
		],
	};
	
	// if is mobile
	if(typeof MOBILE_DEVICE !== 'undefined'){
		erp_noscal.config = Object.assign(erp_noscal.config, {
			contentHeight: 'auto',
		});
	}
	
	// overwrite config
	for(const c in config){
		erp_noscal.config[c] = config[c];
	}
	
	erp_noscal.fullcalendar = new Calendar(calendarEl, erp_noscal.config);
	
	// fields container
	erp_noscal.fields = {
		attendants_list: null,
		
		// is populated in: sys/moduls/erp_noscal/get_js_event_form.inc.php:69
		attendants_list_item_tpl: '',
	};
	
	// peng!
	erp_noscal.fullcalendar.render();
};

/**
 * Displays the event form
 * for creating and editing an event
 *
 * @param {Object} requestVars
 */
erp_noscal.show_event_form = function(requestVars = {}){
	erp_design.load_erp_info({
		modul: 'erp_noscal',
		site: 'interface',
		action: 'js_event_form',
		request_vars: requestVars,
		div_id: 'tmp_get_event_form',
	});
};

/**
 * Saves the edit window form data
 *
 * @param {string} eventId
 */
erp_noscal.save_event_form = function(eventId = null){
	const tmpAjaxObject = erp.load_all_fields_for_ajax('modul_erp_noscal_event', 0, 1, 'extended');
	tmpAjaxObject.event_id = eventId;
	
	// convert attendants from object to array
	const attendantsProp = 'modul_erp_noscal_event__attendants[]';
	if(tmpAjaxObject[attendantsProp]){
		tmpAjaxObject[attendantsProp] = Object.values(tmpAjaxObject[attendantsProp]);
	}
	
	erp_noscal.save_event(tmpAjaxObject, () => {
		erp_design.cancel_erp_info('tmp_get_event_form');
		// reload events to reflect changes
		if(document.getElementById('div_erp_noscal_calendar_widget_list')){
			erp_noscal.calendar_list();
		}else{
			erp_noscal.fullcalendar.refetchEvents();
		}
	});
};

/**
 * Saves an event object to the database
 * A structure that's compatible with "$this->system->mydb->db_save" is expected
 * .. or the plain "erp.load_all_fields_for_ajax" output
 *
 * @param {Object} event
 * @param {function} successCallback
 * @param {function} errorCallback
 */
erp_noscal.save_event = function(event, successCallback = '', errorCallback = ''){
	if(!successCallback){
		successCallback = 'msg';
	}
	if(!errorCallback){
		errorCallback = 'msg';
	}
	ajaxcom({
		m: 'erp_noscal',
		s: 'interface',
		a: 'js_save_event',
		ci: 1,
		q: event,
		hf: {
			success: successCallback,
			error: errorCallback,
		},
	});
};


/**
 * Populate the attendants list
 *
 * @param nosfield
 * @param selectedId
 */
erp_noscal.populate_attendants_list = function(nosfield, selectedId){
	const name = nosfield.elements.view.value;
	
	const proxyContainer = document.createElement('div');
	proxyContainer.innerHTML = erp_noscal.fields.attendants_list_item_tpl
		.replace('[block][modul_erp_noscal_event__attendants_list_item__name]', name)
		.replace('[block][modul_erp_noscal_event__attendants_list_item__id]', selectedId);
	
	// erp_noscal.fields.attendants_list.append(listItem);
	document.querySelector('#modul_erp_noscal_event__attendants_list').append(proxyContainer.firstChild);
};

/**
 * Converts the event date to a php manageable timestamp
 *
 * @param {Date} eventDate
 *
 * @returns {number|*}
 */
erp_noscal.get_event_timestamp = function(eventDate){
	if(!(eventDate instanceof Date)){
		return eventDate;
	}
	
	return Math.floor(eventDate.valueOf() / 1000);
};

/**
 * Transforms a fullcalendar event object to an erp compatible structure
 *
 * @param {Object} event
 *
 * @returns {Object}
 */
erp_noscal.transform_event_object_for_ajax_call = function(event){
	return {
		event_id: event.id,
		title: event.title,
		start: erp_noscal.get_event_timestamp(event.start),
		end: erp_noscal.get_event_timestamp(event.end),
		all_day: event.allDay,
		
		// following fields not relevant to fullcalendar
		/*
		repeat: event.repeat,
		travel_time: event.travelTime,
		alert: event.alert,
		location: event.location,
		url: event.url,
		ticket: event.ticket,
		notes, attendants,
		*/
	};
};

/**
 * Removes the nosfield item from the list
 *
 * @param {HTMLElement} el The element that triggered the click event aka the remove button
 */
erp_noscal.remove_nosfield_item = function(el){
	const li = el.closest('li');
	const parent = li.parentElement;
	const listElements = Array.from(parent.children);
	const index = listElements.indexOf(li);
	parent.children[index].remove();
	
	if(parent.id === 'modul_erp_noscal_event__attendants_list_clone'){
		document.querySelector('#modul_erp_noscal_event__attendants_list').children[index].remove();
	}
};
